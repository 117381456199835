export { camelCase as toCamelCase, kebabCase as toKebabCase } from 'lodash'

export function toPascalCase(value) {
  if (value) {
    return value.match(/[a-z]+/gi).map(word => word.charAt(0).toUpperCase() + word.substr(1).toLowerCase()).join('')
  }

  return value
}

export function latinize(value) {
  return value.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase()
}
