import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)
export const messages = {
  //= =================================================
  //
  //  ##  ##     ##   ####    ##      #####   ####
  //  ##  ####   ##  ##       ##      ##     ##
  //  ##  ##  ## ##  ##  ###  ##      #####   ###
  //  ##  ##    ###  ##   ##  ##      ##        ##
  //  ##  ##     ##   ####    ######  #####  ####
  //
  //= =================================================
  en: {
    abbreviation: 'Abbreviation',
    accepted: 'Accepted',
    acceptedByDefault: 'Accepted by default',
    access: 'Access',
    active: 'Enabled',
    add: 'Add',
    addAttribute: 'Add another attribute',
    addItem: 'Add item',
    addonQuantity: 'Addon quantity',
    address: 'Address|Addresses',
    adjust: 'Adjust',
    agree: 'Agree',
    amount: 'Amount',
    amountPayable: 'Total payable',
    amountToCredit: 'Amount to credit',
    amountWithheld: 'Amount withheld',
    and: 'And',
    answer: 'Aanswer',
    attachment: 'Attachment|Attachments',
    attribute: 'Attribute|Attributes',
    attributeValue: 'Attribute value',
    balance: 'Balance',
    bankCharge: 'Bank charge',
    bankCharges: 'Bank Charges (if any)',
    billing: 'Billing',
    billingInformation: 'Billing information',
    billStatus: {
      open: 'Open',
      paid: 'Paid',
      partialPayment: 'Partial payment',
      partialRefund: 'Partial refund',
      refund: 'Refund'
    },
    birthday: 'Birthday',
    branch: 'Branch|Branches',
    cancel: 'Cancel',
    canceled: 'Canceled',
    canceledBy: 'Canceled by',
    cancellation: 'Cancellation',
    card: 'Card',
    category: 'Category|Categories',
    change: 'Change',
    changeAddress: 'Change address',
    changePassword: 'Change password',
    city: 'City|Cities',
    close: 'Close',
    closedLimit: 'Closed limit',
    code: 'Code',
    codeOptionalAuto: 'Code (If you do not enter it, an automatic code is entered)',
    codeSupplier: 'Supplier code',
    collectionExpense: 'Collection expense',
    column: 'Column|Columns',
    company: 'Company|Companies',
    companyName: 'Company name',
    complete: 'Complete',
    component: 'Component|Components',
    componentOrIngredient: 'Components o ingredients',
    concept: 'concept',
    confirm: 'Are you sure?',
    confirmationCode: 'Confirmation code',
    confirmPassword: 'Confirm Password',
    consecutiveInitial: 'Consecutive initial',
    contact: 'Contact|Contacts',
    contains: 'Contains',
    content: 'Content',
    cost: 'Cost|Costs',
    costInitial: 'Initial cost',
    country: 'Country|Countries',
    create: 'Create',
    created: 'Created',
    createdBy: 'Created by',
    creditAvaible: 'Credit avaible',
    creditBalance: 'Credit balance',
    creditNote: 'Credit note|Credit notes',
    creditNotePurchase: 'Credit note on pruchase|Credit notes on pruchases',
    creditNoteSale: 'Credit note on sale|Credit notes on sales',
    criterion: 'Criterion|Criterions',
    culture: 'Culture',
    currency: 'Currency',
    currentPassword: 'Current Password',
    customerNote: 'Customer note',
    date: 'Date',
    dateFormat: 'Date format',
    day: 'Day|Days',
    debitNote: 'Debit note|Debit notes',
    debitNotePurchase: 'Debit note on purchase|Debit notes on purchases',
    debitNoteSale: 'Debit note on sale|Debit notes on sales',
    decimal: 'decimal|decimals',
    declined: 'Declined',
    delete: 'Delete',
    deletedBy: 'Deleted by',
    department: 'Department',
    description: 'Description',
    detail: 'Detail',
    dimension: 'Dimension|Dimensions',
    dimensionItem: 'Dimensions (Length x Width x Heigth)',
    disabled: 'Disabled',
    discount: 'Discount',
    document: 'Document|Documents',
    documentSetup: 'Documents settings',
    documentType: 'Document type',
    download: 'Download',
    dr: 'Dr.',
    draft: 'Draft',
    dueDate: 'Due date',
    edit: 'Edit',
    email: 'E-mail',
    emailPersonal: 'Personal e-mail',
    emailTemplate: 'E-mail template',
    emailWork: 'E-mail work',
    enabled: 'Enabled',
    enabledLoyalty: 'Enabled loyalty',
    enabledPortal: 'Allow portal access',
    enabledReview: 'Allow review',
    enablePos: 'Enable POS',
    endswith: 'Ends With',
    entity: {
      address: 'Address|Addresses',
      attribute: 'Attribute|Attributes',
      bill: 'Invoicing',
      branch: 'Branch|Branches',
      branchDocument: 'Document setup|Documents setup',
      brand: 'Brand|Brands',
      category: 'Category|Categories',
      company: 'Company|Companies',
      companyPreference: 'Preferences|Preferences',
      contact: 'Contact|Contacts',
      creditNotePurchase: 'Credit note on pruchase|Credit notes on pruchases',
      creditNoteSale: 'Credit note|Credit notes',
      currency: 'Currency|Currencies',
      debitNotePurchase: 'Debit note on purchase|Debit notes on purchases',
      debitNoteSale: 'Debit note|Debit notes',
      discount: 'Discount|Discounts',
      document: 'Document|Documents',
      documentSetup: 'Document setup|Documents setup',
      emailSender: 'Remitente|Remitentes',
      emailTemplate: 'E-mail template|E-mail templates',
      feature: 'Feature|Features',
      home: 'MONOFACTURA|MONOFACTURA',
      invoice: 'Invoice',
      invoicePurchase: 'Purchase invoice|Purchase invoices',
      invoiceSale: 'Invoice|Invoices',
      item: 'Item|Items',
      itemPriceList: 'Prices by list',
      login: 'Login|Login',
      Main: 'Main|Main',
      myAccount: 'My account|My accounts',
      newsletter: 'Newsletter|Newsletters',
      payment: 'Payment',
      paymentType: 'Payment mode|Payment methods',
      person: 'Person|People',
      plan: 'Plan|Plans',
      portal: 'portal',
      pos: 'Point of sale|Points of sale',
      prepayment: 'Prepayment|Prepayments',
      price: 'Price|Prices',
      priceList: 'Price list|Price lists',
      purchaseOrder: 'Purchase order|Purchase orders',
      quotation: 'Quotation|Quotations',
      resolution: 'Resolution|Resolutions',
      saleOrder: 'Sale order|Sale orders',
      service: 'Service|Services',
      tax: 'Tax|Taxes',
      tenant: 'Tenant|Tenants',
      term: 'Terms|Terms',
      unitMeasure: 'Unit of measure|Units of measure',
      user: 'User|Users',
      vendor: 'Vendor|Vendors',
      view: 'View|Views',
      warehouse: 'Warehouse|Warehouses',
      waybillPurchase: 'Purchase waybill|Purchase waybills',
      waybillSale: 'Sales waybill|Sales waybills'
    },
    equal: 'Equal',
    error: 'Error',
    event: 'Event|Events',
    exchangeRate: 'Exchange rate',
    export: 'Export',
    false: 'No',
    favorite: 'Favorite',
    female: 'Female',
    field: 'Field|Fields',
    file: 'File|Files',
    filter: 'Filter|Filters',
    firstName: 'First name',
    flat: 'Flat',
    footer: 'Footer',
    foreignCurrencyTransaction: 'Foreign currency transaction',
    forgotYourPassword: 'Did your forget your password',
    format: 'Format',
    free: 'Free',
    from: 'From',
    gender: 'Gender',
    generateVariants: 'Automatically generate variants',
    greaterThan: 'Greater than',
    greaterThanOrEqualTo: 'Greater than or equal to',
    handleClose: 'Are you sure to close this dialog?',
    hasPos: 'POS',
    hasVariants: 'Tiene variantes',
    heigth: 'Heigth',
    helpPortalPassword: 'Leave the field blank to generate a password automatically',
    hi: 'Hi',
    icon: 'Icon',
    identification: 'Identification',
    identificationNumber: 'ID Number',
    identificationType: 'ID Type',
    identificationYourCompany: 'NIT o CC de tu empresa',
    identificationYourCompanyHelper: 'CC si eres persona natural, NIT si eres persona jurídica',
    image: 'Image|Images',
    initialStock: 'Initial stock',
    insert: 'Create',
    interest: 'Interest',
    invalid: 'Invalid',
    invalidEmail: 'The email is invalid!',
    invalidNumber: 'The number is invalid!',
    inventory: 'Inventario',
    invoice: 'Invoice|Invoices',
    invoicePurchase: 'Purchase invoice|Purchase invoices',
    invoiceToPartner: 'Invoice to partner',
    irreversibleProcess: 'This process can not be reversed!',
    isCash: 'In cash',
    isControlled: 'Is controlled(Monthly)',
    isCustomer: 'Is customer',
    isDefault: 'Is default',
    isEmpty: 'Is empty',
    isForBilling: 'Is for billing',
    isForPurchase: 'Is for purchase',
    isForSale: 'Is for sale',
    isForShipping: 'Is for shipping',
    isNotDefault: 'Is not default',
    isNotEmpty: 'Is not empty',
    IsOnlyWarehouse: 'Is only warehouse',
    isPackage: 'Is package',
    isPartner: 'Is partner?',
    isProvider: 'Is provider',
    isVisible: 'Is visible',
    item: 'Item',
    itemHasInventoryControl: 'The item has inventory control?',
    itemHasPriceList: 'Price defined by list',
    itemPriceIsByMargin: 'Price defined by margin on cost',
    itemWarranty: 'Warranty period (in days)',
    language: 'Lenguage',
    lastDevice: 'Last device',
    lastName: 'Last name',
    lastSession: 'Las session',
    length: 'Length',
    lessThan: 'Less than',
    lessThanOrEqualTo: 'Less than or equal to',
    line1: 'Line 1',
    line2: 'Line 2',
    loayltyProgram: 'Loyalty Program',
    login: 'Login',
    logistics: 'Logistics',
    lookUp: 'Look Up',
    loyalty: 'Loyalty',
    mainSupplier: 'Main supplier',
    male: 'Male',
    manual: 'Manual',
    margin: 'Margin',
    markdown: 'Markdown',
    markup: 'Markup',
    maxDiscount: 'Maximum discount (%)',
    message: 'Message',
    messrs: 'Messrs.',
    miss: 'Miss.',
    mode: 'Mode',
    model: 'Model',
    monthly: 'Monthly',
    moreFields: 'More fields',
    mr: 'Mr.',
    mrs: 'Mrs.,',
    ms: 'Ms.',
    myAccount: 'My account',
    name: 'Name',
    nameYourCompany: 'Nombre de tu empresa',
    nameYourCompanyHelper: 'Coloca tus nombres y apellidos si eres persona natural',
    netToPay: 'Net to pay',
    new: 'New',
    newCategory: 'New category',
    newPassword: 'New password',
    newReply: 'Nuw reply',
    no: 'No',
    noAliaddoEmail: 'E-mail shoud be "@aliaddo.com"',
    notContains: 'Not Contains',
    note: 'Note|Notes',
    notEqual: 'Not Equal',
    number: 'Number',
    of: 'Of',
    ok: 'Ok',
    onSite: 'On website',
    openLimit: 'Open limit',
    operator: 'Operator|Operators',
    or: 'O',
    order: 'Order',
    orderAutomatically: 'Order automatically',
    organization: 'Organization',
    other: 'Other',
    otherData: 'Other data',
    otherFields: 'Other fields',
    outstandingInvoices: 'outstanding invoices',
    overdue: 'Overdue',
    packagePrice: 'Package price',
    packageQuantity: 'Package quantity',
    paid: 'Paid',
    PaidWithoutInvoice: 'Paid without invoice',
    partial: 'Partial',
    partialPaid: 'Partial Paid',
    partner: 'Partner',
    password: 'Password',
    payment: 'Payment|Payments',
    paymentAmount: 'Payment amount',
    paymentDate: 'Payment date',
    paymentFor: 'Payment for',
    paymentInvoice: 'Payment Invoice',
    paymentMethod: 'payment method',
    paymentReceipt: 'Payment receipt',
    paymentsMade: 'Payments made',
    paymentType: 'Payment type',
    percent: 'Percent',
    percentCost: 'Percent cost',
    percentFromCost: '% from cost',
    percentPrice: 'Percent price',
    person: 'Person',
    personType: 'Person type',
    phone: 'Phone',
    phoneCell: 'Phone cell',
    phoneHome: 'Phone home',
    phoneWork: 'Phone work',
    planPriceMonthly: 'Price monthly',
    planPriceYearly: 'Price yearly',
    portalPassword: 'Potal password',
    portalUser: 'Portal user',
    position: 'Position',
    postalCode: 'Código postal',
    prefix: 'Prefix',
    preview: 'Preview',
    price: 'Price|Prices',
    priceList: 'Price list|Price lists',
    processSuccess: 'The process was successfully executed!',
    product: 'Product|Products',
    productWithVariants: 'Product with variants|Products with variants',
    profitAnalysis: 'Profit analysis',
    puchases: 'Purchases',
    purchase: 'Purchase|Purchases',
    purchaseOrder: 'Purchase order|Purchase orders',
    putThis: 'Put this',
    qualification: 'Qualification|Qualifications',
    qualify: 'Qualify',
    quantity: 'Quantity|Quantities',
    quantityUsed: 'Quantity used|Quantities used',
    quarterly: 'Quarterly',
    quotation: 'Quotation',
    range: 'Range',
    rangeFinal: 'Range final',
    rangeInitial: 'Range initial',
    rate: 'Rate|Rates',
    reduction: 'Reduction',
    reference: 'Reference|References',
    refresh: 'Refresh',
    refund: 'Refund',
    regimeType: 'Regime type',
    region: 'Departament, State',
    registerFree: 'Register for free',
    reject: 'Reject',
    rejected: 'Rejected',
    remark: 'Remark',
    reOrderPoint: 'Re-order point',
    reOrderQuantity: 'Re-order quantity',
    reply: 'Reply|Replies',
    required: 'Required',
    requiredNumber: 'Required number',
    requiresPermission: 'Requires permission',
    rescission: 'Rescission',
    returned: 'Returned',
    role: 'Role',
    rounding: 'Rounding',
    row: 'Row|Rows',
    sale: 'Sale|Sales',
    saleOrder: 'Sale order|Sale orders',
    salePrice: 'Sale price',
    sales: 'Sales',
    salutation: 'Salutation',
    save: 'Save',
    search: 'Search',
    searchBy: 'Search by',
    searching: 'Searching...',
    searchItem: 'Search item',
    see: 'See',
    select: 'Select...',
    sell: 'Sell',
    send: 'Send',
    sendEmail: 'Send email',
    sender: 'Sender|Senders',
    sendReply: 'Send reply',
    sendReview: 'Send review',
    sent: 'Sent',
    sequence: 'Secuence',
    service: 'Service',
    setting: 'Setting',
    shared: 'Shared',
    sharedBy: 'Compartido por',
    shipping: 'Shipping',
    shippingCost: 'Shipping cost',
    shippingFee: 'Shipping fee',
    shippingFeeHelp:
      'Shipping fees are excluded from discounts and taxes but they are included in your profit analysis.',
    showing: 'Showing',
    size: 'Size|Sizes',
    start: 'Start',
    startswith: 'Starts With',
    state: 'Sub status',
    status: 'Status',
    statusDian: 'DIAN status',
    stock: 'Stock|Stocks',
    stockInitial: 'Initial stock',
    subject: 'Subject',
    subStatus: 'Sub-status',
    supplier: 'Supplier',
    surname: 'Surname',
    suscribed: 'Suscripción pago automáticosure?',
    symbol: 'Symbol',
    tag: 'Tag|Tags',
    tax: 'Tax|Taxes',
    taxDeducted: 'Tax deducted',
    technicalKey: 'Technical key',
    template: 'Template|Templates',
    term: 'Terms',
    third: 'Third',
    timeFormat: 'Time format',
    timeZone: 'Time zone',
    title: 'Title',
    to: 'To',
    total: 'Total',
    totalParcial: 'Total parcial',
    true: 'Yes',
    type: 'Type|Types',
    unitCost: 'Unit cost',
    unitDiscount: 'Unit discount',
    unitMeasure: 'Unit of measure| Units of measure',
    unitPrice: 'unit price',
    update: 'Update',
    updatedBy: 'Updated by',
    used: 'Used',
    user: 'User',
    username: 'E-mail',
    valid: 'Valid',
    value: 'Value|Values',
    valueChange: 'Value change',
    variant: 'Variant|Variants',
    vendor: 'Vendor',
    void: 'Void',
    warehouse: 'Warehouse|Warehouses',
    warning: 'Warning',
    waybillPurchase: 'Purchase waybill|Purchase waybills',
    waybillSale: 'Sales waybill|Sales waybills',
    website: 'Web site',
    weight: 'Weight',
    weightPerUnit: 'Weight per unit',
    width: 'Width',
    withholding: 'Withholding',
    year: 'Year|Years',
    yearly: 'Yearly',
    yes: 'Yes'
  },

  //= ==============================================================
  //
  //  #####   ####  #####     ###    ##     ##   #####   ##
  //  ##     ##     ##  ##   ## ##   ####   ##  ##   ##  ##
  //  #####   ###   #####   ##   ##  ##  ## ##  ##   ##  ##
  //  ##        ##  ##      #######  ##    ###  ##   ##  ##
  //  #####  ####   ##      ##   ##  ##     ##   #####   ######
  //
  //= ==============================================================
  es: {
    404: 'No se ha encontrado el recurso',
    abbreviation: 'Abreviatura',
    accept: 'Aceptar',
    accepted: 'Aceptada',
    acceptedByDefault: 'Aceptada por tiempo',
    access: 'Acceso',
    accountant: 'Contador',
    accountDemoDaysQuantityAliaddo: 'Tienes 15 días para que pruebes nuestro servicio.',
    accountDemoDaysQuantityFe: 'Tienes 7 días para que pruebes nuestro servicio.',
    acepted: 'Aceptado',
    action: 'Acción|Acciones|acción|acciones',
    active: 'Activo',
    add: 'Agregar',
    addAddOns: 'Puedes adicionar los siguientes complementos a tu plan',
    addAttribute: 'Agregar otro atributo',
    addItem: 'Agregar ítem',
    addMoreAddress: 'Adicionar más direcciones',
    addMoreEmails: 'Adicionar más correos electrónicos',
    addOn: 'Complementos',
    addOnPrice: '$ Adicional|Precio adicional',
    addOnQuantity: 'Cantidad adicional|Cant. Adicional',
    address: 'Dirección|Direcciones',
    addressLine: 'Dirección',
    aditional: 'Adicional|Adicionales|adicional|adicionales',
    adjust: 'Ajuste',
    agree: 'Aceptar',
    aliaddo: 'Aliaddo SAS',
    aliaddoNit: '830099008-5',
    all: 'Todo|Todos|todo|todos',
    alreadyHasAccount: '¿Ya tienes cuenta con nosotros?',
    amount: 'Valor',
    amountPayable: 'Total a pagar',
    amountToCredit: 'Valor a usar',
    amountWithheld: 'Valor retenido',
    and: 'Y',
    annuled: 'Anulado|anulado|Anulados|anulados',
    answer: 'Responder',
    appNoReloadedMessage: 'Recuerde recargar la página mas tarde para tomar las actualizaciones',
    appReload: 'Hemos actualizar la aplicación',
    appReloadMessage:
      'Es necesario recargar la página para tomar las actualizaciones, recuerde guardar antes de realizarlo. Desea recargar la página ?',
    approved: 'Aprobada|aprobada',
    approvedPartial: 'Aprobada parcialmente|aprobada parcialmente',
    askedQuestionsElectronicPayments: 'PREGUNTAS FRECUENTES SOBRE PAGOS ELECTRÓNICOS',
    assingPerson: 'Asignar tercero',
    attachment: 'Adjunto|Adjuntos',
    attribute: 'Atributo|Atributos',
    attributeValue: 'Valor del atributo',
    authorization: 'Autorización',
    balance: 'Saldo',
    bank: 'Franquicia',
    bankCharge: 'Cargo bancario',
    bankCharges: 'Cargos bancarios (si los hay)',
    base: 'Base|base',
    billing: 'Facturación',
    billingInformation: 'Datos de facturación',
    billStatus: {
      open: 'Vigente',
      paid: 'Pagado',
      partialPayment: 'Pago parcial',
      partialRefund: 'Devolucion parcial',
      refund: 'Devolucion'
    },
    birthday: 'Fecha de nacimiento',
    blog: 'Blog',
    branch: 'Sucursal|Sucursales',
    byPlan: 'Por la recurrencia del plan',
    byRecurrencePlan: 'Por recurrencia plan',
    cancel: 'Cancelar',
    canceled: 'Anulada',
    canceledBy: 'Anulado por',
    cancellation: 'Anulación',
    cancelled: 'Cancelado',
    card: 'Tarjeta',
    category: 'Categoria|Categorias',
    chamberCommerce: 'Camara y comercio',
    change: 'Cambiar',
    changeAddress: 'Cambiar dirección',
    changePassword: 'Cambiar contraseña',
    changePlan: 'Cambio de plan',
    checkPolicy:
      'Para poder iniciar a disfrutar los beneficios de tu cuenta es necesario aceptar los términos y condiciones junto a la política de tratamiento de datos',
    city: 'Ciudad|Ciudades',
    clicked: 'Click',
    clickHere: 'Ingresar aquí',
    clickUpload: 'Click para subir tus archivos',
    close: 'Cerrar',
    closedLimit: 'Limite cerrado',
    code: 'Código',
    codeOptionalAuto: 'Código (Si no lo coloca se ingresa un consecutico automático)',
    codeSupplier: 'Código del producto según el proveedor',
    collect: 'Cobrar',
    collectAutomatic: 'Cobro automático',
    collectAutomaticMessage:
      'Esta seguro de realizar el cobro automático con la subscripción que realizó el cliente',
    collectFailded: 'Cobro fallido',
    collectFaildedMessage: 'No se pudo realizar el cobro con la suscripción',
    collectionExpense: 'Gastos por cobrar',
    collectRecurrence: 'Recurrencia cobro',
    collectSuccess: 'Se ha realizado el recaudo correctamente',
    column: 'Columna|Columnas',
    company: 'Empresa|Empresas',
    'company.companyIdentification': 'Identificación empresa',
    'company.companyName': 'Razón social de la empresa',
    companyName: 'Razón social',
    complement: 'Complemento|Complementos',
    complementYourPlan: 'Complementa tu plan',
    complete: 'Completa',
    component: 'Componente|Componentes',
    componentOrIngredient: 'Componentes o ingredientes',
    concept: 'Concepto',
    confirm: 'Esta seguro?',
    confirmationCode: 'Código de confirmación',
    confirmPassword: 'Confirmar contraseña',
    consecutive: 'Consecutivo',
    consecutiveInitial: 'Consecutivo inicial',
    contact: 'Contacto|Contactos',
    contains: 'Contiene',
    content: 'Contenido',
    continue: 'Continuar',
    contractMandate: 'Contrato mandato',
    contributor: 'Contribuyente',
    copyright: 'Copyright © 2023 - Aliaddo SAS, Todos los derechos reservados',
    cost: 'Costo|Costos',
    costInitial: 'Costo inicial',
    country: 'País|Paises',
    create: 'Crear',
    createAccount: 'Crear cuenta',
    created: 'Creado',
    createdBy: 'Creado por',
    createYourAccount: 'Crea tu cuenta',
    creditAvaible: 'Crédito disponible',
    creditBalance: 'Saldo a favor',
    creditCard: 'Tarjeta de crédito',
    creditNote: 'Nota crédito|Notas crédito',
    creditNotePurchase: 'Nota crédito en compras|Notas crédito en compras',
    creditNoteSale: 'Nota crédito en ventas|Notas crédito en ventas',
    criterion: 'Criterio|Criterios',
    culture: 'Cultura',
    currency: 'Moneda',
    current: 'Actual',
    currentPassword: 'Contraseña actual',
    currentPlan: 'Tu plan actual',
    cus: 'CUS',
    customer: 'Cliente',
    customerNote: 'Nota del cliente',
    dataBuyer: 'Datos comprador',
    dataCompany: 'Datos de tu empresa',
    dataForBilling: 'Datos facturación',
    dataPayer: 'Datos pagador',
    dataPayerChangeHelper:
      'Si los datos del propietario de la tarjeta o cuenta son diferentes al comprador estos datos los puedes cambiar en el siguiente paso dando cambiar datos del pagador',
    dataUser: 'Datos de tu usuario',
    dataYourAccount: 'Datos de tu cuenta',
    dataYourCompany: 'Datos de tu empresa',
    date: 'Fecha',
    dateDue: 'Fecha vencimiento',
    dateEnd: 'Fecha final',
    dateEndTrialPeriod: 'Fin periodo prueba',
    dateFirstInvoice: 'Primera factura',
    dateFormat: 'Formato de fecha',
    dateNextInvoice: 'Siguiente factura',
    dateStart: 'Fecha inicio',
    dateTime: 'Fecha y hora',
    day: 'Dia|Dias',
    dayTrial: 'Día pruebas|Días en pruebas',
    debitNote: 'Nota débito|Notas débito',
    debitNotePurchase: 'Nota débito en compras|Notas débito en compras',
    debitNoteSale: 'Nota débito en ventas|Notas débito en ventas',
    decimal: 'Decimal|Decimales',
    declined: 'Rechazada',
    delete: 'Eliminar',
    deletedBy: 'Eliminado por',
    deleteMessage: 'Esta seguro de eliminar el registro',
    deleteSubscription: 'Eliminar suscripción',
    deleteSubscriptionConfirmMessage:
      'Estas seguro de eliminar tu suscripción al pago automático, de  ahora en adelante debes realizar tu pago de acuerdo a tu periocidad, puedes volverte a suscribir al momento de realizar un pago',
    delivered: 'Entregada',
    demand: 'Demanda',
    department: 'Departamento',
    description: 'Descripción',
    detail: 'Detalle',
    dimension: 'Dimensión|Dimensiones',
    dimensionItem: 'Dimensiones (Largo x Ancho x Alto)',
    disabled: 'Inactivo',
    discount: 'Descuento',
    document: 'Documento|Documentos',
    documentSetup: 'Configuración de documentos',
    documentType: 'Tipo de documento',
    download: 'Descargar',
    dr: 'Dr.',
    draft: 'Borrador',
    dropHere: 'Arrastra tus archivos acá',
    due: 'Vence',
    dueDate: 'Vencimiento',
    edit: 'Editar',
    effected: 'Efectuado|Efectuados|Efectuada|Efectuadas',
    eighthYearly: 'Cada ocho años',
    email: 'E-mail',
    emailInvalid: 'El campo es de tipo email,  debe colocar un email valido ejemplo@proveedor.com',
    emailNotificationSuccess: 'Mensaje de email enviado con exito',
    emailPersonal: 'E-mail personal',
    emailTemplate: 'Plantilla de e-mail',
    emailWork: 'E-mail trabajo',
    emptyCreditCard: 'Debe seleccionar una franquicia',
    emptyFieldInvalid: 'El campo no puede estar vacio',
    enabled: 'Activo',
    enabledLoyalty: 'Habilitar fidelización',
    enabledPortal: 'Permitir acceso al portal',
    enabledReview: 'Permitir calificar',
    enablePos: 'Activar POS',
    end: 'Fin',
    endswith: 'Finaliza con',
    enterField: 'Debes ingresar: {field}',
    entity: {
      address: 'Dirección|Direcciones',
      attribute: 'Atributo|Atributos',
      bill: 'Facturación',
      branch: 'Sucursal|Sucursales',
      branchDocument: 'Configuracion de documento|Configuracion de documentos',
      brand: 'Marca|Marcas',
      category: 'Categoria|Categorias',
      company: 'Empresa|Empresas',
      companyPreference: 'Preferencias|Preferencias',
      contact: 'Contacto|Contactos',
      creditNotePurchase: 'Nota crédito en compras|Notas crédito en compras',
      creditNoteSale: 'Nota crédito|Notas crédito',
      currency: 'Moneda|Monedas',
      debitNotePurchase: 'Nota débito en compras|Notas débito en compras',
      debitNoteSale: 'Nota débito|Notas débito',
      discount: 'Descuento|Descuentos',
      document: 'Documento|Documentos',
      documentSetup: 'Configuracion de documento|Configuracion de documentos',
      emailSender: 'Remitente|Remitentes',
      emailTemplate: 'Plantilla de e-mail|Plantillas de e-mail',
      feature: 'Caracteristica|Caracteristicas',
      home: 'MONOFACTURA|MONOFACTURA',
      invoice: 'Factura',
      invoicePurchase: 'Factura de compra|Facturas de compra',
      invoiceSale: 'Factura de venta|Facturas de venta',
      item: 'Item|Items',
      itemPriceList: 'Precios por lista',
      login: 'Login|Login',
      Main: 'Principal|Principal',
      myAccount: 'Mi cuenta|Mis cuentas',
      newsletter: 'Boletin de noticia|Boletines de noticias',
      payment: 'Pago|pago|Pagos|pago',
      paymentType: 'Forma de pago|Formas de pago',
      person: 'Tercero|Terceros',
      plan: 'Plan|Planes',
      portal: 'portal',
      pos: 'Punto de venta|Puntos de venta',
      prepayment: 'Anticipo|Anticipos',
      price: 'Precio|Precios',
      priceList: 'Lista de precio|Listas de precios',
      purchaseOrder: 'Órden de compra|Órdenes de compra',
      quotation: 'Cotización|Cotizaciones',
      register: 'Caja|Cajas',
      resolution: 'Resolución|Resoluciones|resolución|resoluciones',
      saleOrder: 'Orden de venta|Órdenes de venta',
      service: 'Servicio|Servicios',
      tax: 'Impuesto|Impuestos',
      taxMode: 'Modo impuesto|modo impuesto|Modos impuesto|modos impuesto',
      tenant: 'Tenant|Tenants',
      term: 'Plazo|Plazos',
      unitMeasure: 'Unidad de medida|Unidades de medida',
      user: 'Usuario|Usuarios',
      vendor: 'Vendedor|Vendedores',
      view: 'Vista|Vistas',
      warehouse: 'Bodega|Bodegas',
      waybillPurchase: 'Remisión de compra|Remisiones de compra',
      waybillSale: 'Remisión de venta|Remisiones de venta'
    },
    equal: 'Igual',
    error: 'Error|error',
    event: 'Evento|Eventos',
    exampleUserEmail: 'ejemplo: pedroperez@mail.com',
    exampleUserName: 'ejemplo: pedroperez',
    exchangeRate: 'Tarifa de conversión',
    export: 'Exportar',
    failed: 'Fallida|fallida',
    FAILED: 'Fallido',
    false: 'No',
    favorite: 'Favorito',
    feature: 'Característica|Características',
    featureAddOn: 'Adicionales',
    featureOfCompany: 'Características de la empresa',
    featureOfPlan: 'Características del plan',
    female: 'Mujer',
    field: 'Campo|Campos',
    fieldHasInvalidCharacter: 'Este campo contiene carácteres no permitidos',
    fieldRequired: 'Campo requerido',
    file: 'Archivo|Achivos',
    fileRut: 'Archivo pdf con un tamaño no mayor a 2 MB',
    filter: 'Filtro|Filtros',
    firstName: 'Nombres',
    firstPeriod: 'Primer periodo|Primeros periodos',
    flat: 'Plano',
    footer: 'Pie de pagina',
    foreignCurrencyTransaction: 'Transacción en moneda extranjera',
    forgotYourPassword: 'Olvidó su contraseña',
    format: 'Formato',
    franchise: 'Franquicia',
    franchiseName: 'Nombre franquicia',
    frecuencyBillingInvalid:
      'Periodicidad? valida de nuevo el plan seleccionado  y la frecuencia de pago',
    free: 'Gratis',
    from: 'Desde',
    fullName: 'Nombres y Apellidos',
    gender: 'Género',
    generateVariants: 'Generar las variantes automaticamente',
    globalDemand: 'Demanda Global',
    greaterThan: 'Mayor que',
    greaterThanOrEqualTo: 'Mayor o igual que',
    handleClose: 'Estas seguro de cerrar esta ventana?',
    has: 'Tiene',
    hasPartner: 'Pertenece a un partner',
    hasPerson: 'Tiene tercero asignado',
    hasPos: 'POS',
    hasVariants: 'Tiene variantes',
    heigth: 'Alto',
    hello: 'Bienvenido',
    helpPortalPassword: 'Deje el campo en blanco para generar una contraseña automaticamente.',
    helpVoucherDateDue:
      'Fecha de vencimiento del comprobante, si es uno de apertura esta va a ser la fecha de finalización del periodo de prueba',
    helpVoucherIsFirst:
      'Se esta creando el primer comprobante a la empresa? si es asi se va a aprovisionar la cuenta con lo que se este facturando',
    hi: 'Hola',
    homologate: 'Homologar',
    icon: 'Icono',
    identification: 'Identificación',
    identificationCheck: 'Dígito de verificación|Dv',
    identificationNumber: 'No. de identificacion',
    identificationType: 'Tipo de identificación',
    identificationTypeYourCompany: 'Tipo documento de tu empresa',
    identificationYourCompany: 'Documento de tu empresa',
    identificationYourCompanyHelper: 'CC si eres persona natural, NIT si eres persona jurídica',
    image: 'Imagen|Imagenes',
    included: 'Incluido',
    increase: 'Se suma',
    incrementalPlan: 'Adicionar características al plan',
    individualDemand: 'Demanda Individual',
    info: 'Informativo',
    initial: 'Inicial',
    initialStock: 'Stock inicial',
    insert: 'Crear',
    interest: 'Interés',
    invalid: 'Invalida',
    invalidEmail: 'El email no es válido!',
    invalidNumber: 'El número no es válido!',
    inventory: 'Inventario',
    invoice: 'Factura|Facturas',
    invoicePurchase: 'Factura de compra|Facturas de compra',
    invoiceSale: 'Factura de venta|Facturas de venta',
    invoiceToPartner: 'Facturar al socio',
    ip: 'Ip',
    irreversibleProcess: 'Este proceso no se puede revertir!',
    isCash: 'En efectivo',
    isControlled: 'Es controlado (Mensual)',
    isCustomer: 'Es cliente',
    isDefault: 'Predeterminado',
    isEmpty: 'Está vacio',
    isEnabled: 'Activo?',
    isForBilling: 'Es para facturación',
    isForPurchase: 'Es para comprar',
    isForSale: 'Es para la venta',
    isForShipping: 'Es para entrega',
    isIncluded: 'Esta incluido en el precio?',
    isMandatory: 'Es obligatorio',
    isNotDefault: 'No está predeterminado',
    isNotEmpty: 'No está vacio',
    IsOnlyWarehouse: 'Unicamente es una bodega',
    isPackage: 'Es paquete',
    isPartner: '¿Es un socio?',
    IsPayVat: 'Es responsable de IVA',
    isProvider: 'Es proveedor',
    isSimpleRegime: 'Es régimen simple',
    isSuscribed:
      'Estas suscrito al pago automático, no debes preocuparte porque se te olvido pagar a tiempo y tu cuenta quedó en modo lectura y por estar ingresando todos los meses al portal de tu banco',
    isVisible: 'Es visible',
    item: 'Item',
    itemHasInventoryControl: 'Tiene control de inventario?',
    itemHasPriceList: 'Precio definido por lista',
    itemPriceIsByMargin: 'Precio definido por margen sobre el costo',
    itemWarranty: 'Período de garantía (en días)',
    iva: 'Iva|iva',
    iWantItThis: 'Lo quiero así !!',
    language: 'Lenguaje',
    lastDevice: 'Último dispositivo',
    lastDigits: 'Ultimos dígitos',
    lastName: 'Apellidos',
    lastSession: 'Última sesión',
    legalRepresentative: 'Representante legal',
    length: 'Largo',
    lessThan: 'Menor que',
    lessThanOrEqualTo: 'Menor o igual',
    level: 'Nivel',
    line1: 'Linea 1',
    line2: 'Linea 2',
    loayltyProgram: 'Pograma de fidelización',
    log: 'Log',
    logEmail: 'Log envio email',
    login: 'Iniciar sesión',
    logistics: 'Logistica',
    lookUp: 'Consultar',
    loyalty: 'Fidelización',
    mainSupplier: 'Principal proveedor',
    male: 'Hombre',
    manual: 'Manual',
    margin: 'Margen',
    markdown: 'Disminuir',
    markup: 'Aumentar',
    maxDiscount: 'Descuento máximo (%)',
    message: 'Mensaje',
    messageNoFoundPlan:
      'No hemos encontrado el plan, comunicate con tu asesor comercial para validar el plan',
    messagePlanPriceError: 'Periodo invalido, comuniquese con soporte técnico!',
    messrs: 'Sres.',
    method: 'Método',
    miss: 'Srta.',
    mobile: 'Telefóno (Celular)',
    mode: 'Modalidad',
    model: 'Modelo',
    month: 'Mes',
    monthly: 'Mensual',
    moreFields: 'Más campos',
    motive: 'Motivo',
    mr: 'Sr.',
    mrs: 'Sra.',
    ms: 'Dña.',
    mustSelectVendor: 'Se debe seleccionar un vendedor',
    myAccount: 'Mi cuenta',
    name: 'Nombre|Nombres|nombre|nombres',
    nameYourCompany: 'Nombre de tu empresa',
    nameYourCompanyHelper: 'Coloca tus nombres y apellidos si eres persona natural',
    nbxrpending: 'Pendiente',
    neighborhood: 'Barrio',
    netToPay: 'Neto a pagar',
    new: 'Nueva',
    newCategory: 'Nueva categoría',
    newPassword: 'Nueva Contraseña',
    newReply: 'Nueva respuesta',
    next: 'Siguiente',
    nextInvoice: 'Siguiente factura',
    nit: 'Nit',
    no: 'No.',
    noAliaddoEmail: 'El E-mail debe ser de aliaddo "@aliaddo.com"',
    noFoundVoucherMessage:
      'No hemos encontrado tu comprobante, por favor comunicate con facturación',
    noHasPayment: 'No se ha encontrado el pago dentro de la sesión',
    none: 'Ninguno',
    noPayment: 'Pago no procesado',
    noPerson: 'Sin tercero asignado',
    notContains: 'No contiene',
    note: 'Nota|Notas',
    notEqual: 'No es igual',
    noVoucherFound:
      'No se ha encontrado la orden de venta, por favor comuniquese con el departamento de facturación',
    number: 'Número',
    numberDocument: 'Número de documento',
    observation: 'Observaciones',
    of: 'De',
    ok: 'Ok|ok',
    onlyCreditAndDebitCard: 'Para pagos con tarjeta de crédito y débito',
    onlyCreditCard: 'Solo para pagos con tarjeta de crédito',
    onSite: 'En sitio web',
    open: 'Vigente',
    opened: 'Abierto',
    opening: 'Apertura',
    openLimit: 'Limite abierto',
    operator: 'Operador|Operadores',
    or: 'O',
    order: 'Orden',
    orderAutomatically: 'Ordenar automaticamente',
    organization: 'Organización',
    original: 'Original',
    other: 'Otro',
    otherData: 'Otros datos',
    otherFields: 'Otros campos',
    outstandingInvoices: 'Facturas pendientes',
    overdue: 'Vencimiento',
    overwriteFeaturesCompanyService:
      'Esta seguro de sobreescribir las características de la empresa por las actuales del plan',
    package: 'Paquete|paquete|Paquetes|paquetes',
    packagePrice: 'Precio del paquete',
    packageQuantity: 'Cantidad del paquete',
    packages: 'Paquetes',
    paid: 'Pagado',
    paidWithoutInvoice: 'Pagado - sin crear factura',
    partial: 'Parcial',
    partialPaid: 'Pago parcial',
    partialPayment: 'Pago parcial',
    partner: 'Socio',
    password: 'Contraseña',
    pay: 'Pagar',
    payed: 'Pagó',
    payment: 'Pago|Pagos',
    paymentAction: 'Realiza tu pago',
    paymentAmount: 'Valor del pago',
    paymentBasic: 'Pago básico, pagar periodo a periodo',
    paymentBasicMessage: 'Para pagar cada vez que se venza tu recurrencia de facturación',
    paymentByCollect: 'Pagar mediante suscripción',
    paymentByCollectMessage: 'Va a realizar el pago automático con la subscripción que realizó.',
    paymentData: 'Datos para el pago',
    paymentDate: 'Fecha de pago',
    paymentError: 'Pago con error',
    paymentFor: 'Pago para',
    paymentForPlan: 'El total a pagar por el plan seleccionado',
    paymentHeader:
      'Realiza el pago de tus servicios de manera segura, usando tu cuenta bancaria o tarjeta de crédito.',
    paymentInitial: 'Pago inicial',
    paymentIsGreatherThanCero: 'El pago debe ser mayor a cero',
    paymentMethod: 'Forma de pago',
    paymentOnlyTime: 'Pago única vez',
    paymentPending: 'Tienes un pago pendiente por notificación de la entidad bancaria',
    paymentReceipt: 'Recibo de pago',
    paymentRejected: 'Pago rechazado',
    paymentsMade: 'Pagos realizados',
    paymentSubscribe: 'Subscribirse al pago automático',
    paymentSubscribeMessage:
      'Utiliza este método si quiere suscribirse al cobro automático para que el pago se aplique cada recurrencia y en el monto pactado, con esto te evitas estar  pendiente de si realizaste el pago o no, adicionalment de ir a tu banco a realizar el pago, ALIADDO SAS no almacena datos de tu tarjeta o cuenta bancaria esto se realiza por medio de la plataforma de pagos',
    paymentSuccess: 'Pago efectuado exitosamente',
    paymentSummary: 'Resumen pago',
    paymentType: 'Forma de pago',
    paymentWithStatus: 'El pago se encuentra en estado {status}',
    paySummary: 'Resumen a pagar',
    pdfRut: 'Pdf con tu RUT',
    pending: 'Pendiente|pendiente',
    pendingValidation: 'Pendiente validación|pendiente validación',
    percent: 'Porcentaje',
    percentCost: 'Porcentaje sobre el costo',
    percentDiscount: 'Porcentaje descuento',
    percentFromCost: '% del costo',
    percentPrice: 'Porcentaje sobre el precio',
    period: 'Periodo',
    periodicity: 'Periodicidad',
    periodQuantityHelp: 'Cuantos meses o de acuerdo a recurrencia va a contar con la promoción',
    periodsQuantity: 'No. periodos con descuento',
    person: 'Tercero',
    personalizeYourPlan: 'Personaliza tu plan',
    personType: 'Tipo persona|tipo persona',
    phone: 'Teléfono',
    phoneCell: 'Número celular',
    phoneHome: 'Teléfono hogar',
    phoneWork: 'Teléfono trabajo',
    placePlan: 'Colocar las del plan',
    placeToPay: 'PlaceToPay',
    placeToPayPaymentsFree:
      'Recuerde que los pagos por este medio a usted no le genera ningún costo adicional.',
    placeToPayTransactionStatus: 'Estado transacción',
    plan: 'Plan|Planes|plan|planes',
    planOriginal: 'Plan original',
    planPriceMonthly: 'Precio mensual',
    planPriceQuarterly: 'Precio trimestral',
    planPriceSemiannual: 'Precio semestral',
    planPriceYearly: 'Precio anual',
    portalPassword: 'Contraseña del potal',
    portalUser: 'Usuario del potal',
    position: 'Cargo',
    postalCode: 'Código postal',
    prefix: 'Prefijo',
    preview: 'Vista previa',
    price: 'Precio|Precios',
    priceAdditional: '$ adicional',
    priceAddOn: 'Precio adicional',
    priceAditional: 'Precio adicional',
    priceImplementation: 'Valor implementación',
    priceList: 'Lista de precio|Listas de precios',
    priceNew: 'Precio nuevo con descuento',
    priceUnit: 'Precio unitario',
    print: 'Imprimir',
    printVoucher: 'Imprimir Comprobante',
    process: 'En proceso',
    processSuccess: 'El proceso se ejecutado con éxito!',
    product: 'Producto|Productos',
    productWithVariants: 'Producto con variantes|Productos con variantes',
    profile: 'Perfil|Perfiles|perfil|perfiles',
    profitAnalysis: 'Análisis de resultado',
    promotion: 'Promoción|Promociones',
    puchases: 'Compras',
    purchase: 'Compra|Compras',
    purchaseOrder: 'Órden de compra|Órdenes de compra',
    purchasePackage: 'Comprar adicional',
    putThis: 'Colocar este',
    qualification: 'Calificación|Calificaciónes',
    qualify: 'Calificar',
    quantity: 'Cantidad|Cantidades',
    quantityAditional: 'Cantidad adicional',
    quantityUsed: 'Cantidad utilizada|Cantidades utilizadas',
    quarterly: 'Trimestral',
    quotation: 'Cotización|Cotizaciones',
    range: 'Rango',
    rangeFinal: 'Rango final',
    rangeInitial: 'Rango inicial',
    rate: 'Tarifa|Tarifas',
    read: 'Consultar',
    reason: 'Motivos',
    recipient: 'Destinatario',
    recurrence: 'Recurrencia',
    recurrentCollect: 'Cobro recurrente?',
    reduction: 'Reducción',
    reference: 'Referencia|Referencias',
    refresh: 'Refrescar',
    refund: 'Devolución',
    refunded: 'Devuelto|devuelto',
    regimeType: 'Tipo de regimen',
    region: 'Departamento',
    registerForFree: 'Registrate gratis',
    registerFree: 'Registrate Gratis',
    registerMessage: 'Estamos emocionados de tenerte como cliente.',
    registerMessageHelper:
      'Te enviaremos un email con los datos de acceso a tu cuenta: <strong>{email}</strong>.',
    registerMessageTitle: '¡Gracias por registrarte!',
    reject: 'Rechazar',
    rejected: 'Rechazada|rechaza',
    reload: 'Recargar',
    remark: 'Comentario',
    reOrderPoint: 'Punto de re-orden',
    reOrderQuantity: 'Cantidad para re-orden',
    reply: 'Respuesta|Respuestas',
    requentlyAskedQuestions: 'Preguntas frecuentes',
    required: 'Requerido',
    requiredNumber: 'El valor debe ser un número',
    requiresPermission: 'Requiere permiso',
    cardSubscribed: 'Tarjeta suscrita',
    rescission: 'Rescisión',
    resolution: 'Resolution|Resolutions|resolución|resoluciones',
    resolution0689:
      'Quedamos exentos de renta por resolución 0689 del 1 de junio 2021 por tanto, no nos debes aplicar retención en la fuente, click para ver ',
    responsibilities: 'Responsabilidades fiscales|responsabilidades fiscales',
    result: 'Resultado',
    reteFuente: 'ReteFuente',
    reteIca: 'Rete ICA',
    reteIva: 'Rete IVA',
    retry: 'Volver a intentar',
    returned: 'Devuelta',
    returnService: 'Ir a la aplicación',
    returnToStoreHelper:
      'Recuerda cuando termines de realizar el pago en el banco y PlaceToPay, dar click en retornar al sitio para procesar tu transacción lo mas pronto posible',
    role: 'Rol',
    rounding: 'Redondeo',
    row: 'Fila|Filas',
    rut: 'Rut',
    sale: 'Venta|Ventas',
    saleOrder: 'Orden de venta|Órdenes de venta',
    salePrice: 'Precio de venta',
    sales: 'Ventas',
    salutation: 'Saludo',
    save: 'Guardar',
    saved: 'Registro guardado con exito',
    search: 'Buscar',
    searchBy: 'Buscar por',
    searchByMode: 'Buscar por modo',
    searchByService: 'Buscar por servicio',
    searchByStatusIsPackage: 'Buscar paquetes por estado',
    searchByStatusNoPackage: 'Buscar no empaquetados por estado',
    searching: 'Buscando...',
    searchItem: 'Buscar item',
    see: 'Ver',
    select: 'Seleccione...|Seleccione',
    selectFile: 'Debes seleccionar un archivo: {file}',
    selectYourCreditCard: 'Selecciona tu tarjeta',
    sell: 'Vender',
    seller: 'Vendedor',
    semiannual: 'Semestral',
    send: 'Enviar',
    sendEmail: 'Enviar correo',
    sender: 'Remitente|Remitentes',
    sendReply: 'Enviar respuesta',
    sendReview: 'Enviar evaluación',
    sendVoucherEmail: 'Deseas enviar la orden por email',
    sent: 'Enviada',
    sequence: 'Consecutivo',
    service: 'Servicio',
    serviceStatus: 'Estado del servicio',
    setting: 'Configuración',
    shared: 'Compartido',
    sharedBy: 'Compartido por',
    shipping: 'Envío',
    shippingCost: 'Costo de envío',
    shippingFee: 'Cargos de envío',
    shippingFeeHelp:
      'Los gastos de envío están excluidos de los descuentos e impuestos, pero están incluidos en su análisis de beneficios.',
    showing: 'Mostrando',
    sign: 'Firma',
    signOwner: 'Firmando con firma propia',
    signup: 'Registrarse',
    signupMessageAddress:
      'La dirección principal es la de la parte superior, pero puedes adicionar otras direcciones si deseas.',
    signupMessageEmail:
      'Puedes adicionar mas correos para que te lleguen las facturas correspondientes a tu servicio, recuerda que el usuario SuperAdministrador va a ser el del paso (1)',
    signWithApp: 'Firmado con Aliaddo: {value}',
    size: 'Tamaño|Tamaños',
    start: 'Inicio',
    startswith: 'Empieza por',
    startWithAccount: 'Comenzar a utilizar mi cuenta',
    state: 'Sub estado',
    status: 'Estado',
    statusDate: 'Fecha estado',
    statusDian: 'Estado DIAN',
    statusPayment: 'Estado pago',
    statusReason: 'Motivos estado',
    stock: 'Existencia|Existencias',
    stockInitial: 'Inventario inicial',
    subject: 'Asunto',
    subscribe: 'Subscribirse',
    subscribeRecurrentCancel:
      'En cualquier momento puede cancelar la suscripción al cobro recurrente',
    subscribeRecurrentCollect: 'Desea subscribirse al cobro recurrente?',
    subscription: 'Suscripción',
    subscriptionFailed: 'Suscripción Rechazada',
    subscriptionFailedMessage:
      'No se ha aceptado la inscripción correctamente del cobro automático por favor valide con su entidad bancaria.',
    subscriptionMessage: '',
    subscriptionOk: 'Suscripción aprobada',
    subscriptionOkMessage:
      'De ahora en adelante los pagos de tus servicios con ALIADDO SAS van a ser descontados en la frecuencia pactada y por los valores pactados, en cualquier momento puedes cancelar esta suscripción.',
    subscriptionPending: 'Suscripción pendiente',
    subscriptionPendingMessage: 'Estamos a la respuesta de la entidad bancaria',
    subscriptionRejected: 'Suscripción Rechazada',
    subscriptionRejectedMessage:
      'No se ha aceptado la inscripción correctamente del cobro automático por favor valide con su entidad bancaria.',
    subStatus: 'Sub-estado',
    subtotal: 'Subtotal',
    supplier: 'Proveedor',
    support: 'Soporte',
    surname: 'Apellido',
    suscribe: 'Suscribirse',
    suscribed: 'Suscripción pago automático',
    symbol: 'Símbolo',
    tag: 'Etiqueta|Etiquetas',
    takeFromOriginalPlan: 'Tomar características actuales del plan',
    takeFromOriginalPlanMessage:
      'Marque para sobreescribir las carasterísticas del plan de la empresa con las actuales del plan',
    takeOrigin: 'Tomar original',
    tax: 'Impuesto|Impuestos',
    taxDeducted: 'Impuesto retenido',
    taxe: 'Impuesto|impuesto|Impuestos|impuestos',
    taxes: 'Impuesto|impuesto|Impuestos|impuestos',
    taxIncluded: '% Iva Incluido',
    taxIncrease: '% Más Iva',
    taxIsIncluded: 'Impuesto esta incluido?',
    taxPercent: '% Iva',
    technicalKey: 'Clave técnica',
    template: 'Plantilla|Plantillas',
    term: 'Plazo',
    third: 'Tercero',
    threeYearly: 'Cada tres años',
    timeFormat: 'Formato de hora',
    timeZone: 'Zona horaria',
    title: 'Titulo',
    to: 'Hasta',
    total: 'Total',
    totalParcial: 'Subtotal',
    transaction: 'Transacción',
    transactionApproved: 'Pago aprobado',
    transactionApprovedMessage:
      'El pago ha sido recibido exitosamente, gracias por realizar tu pago',
    transactionInProcess: 'Transacción en proceso',
    transactionInProcessMessage:
      'Existe ya una transacción en proceso, debe esperar a que se notifique por parte de la entidad bancaria el estado de la misma',
    transactionMessage: 'Pago aprobado',
    transactionPending: 'Pago pendiente',
    transactionPendingMessage: 'Estamos esperando a que la entidad financiera reporte el pago',
    transactionRejected: 'Pago rechazado',
    transactionRejectedMessage:
      'No se pudo procesar el pago, por favor valide con su entidad bancaria',
    transactionundefinedMessage: 'Transacción en estado pago',
    true: 'Si',
    type: 'Tipo|Tipos',
    typeForSearchCity: 'Digita para buscar ciudad',
    typeForSearchDepartament: 'Digita para buscar departamento',
    unauthorized:
      'No esta autorizado para realizar esta acción, se ha enviado el requerimiento al administrador',
    unitCost: 'Costo unitario',
    unitDiscount: 'Descuento unitario',
    unitMeasure: 'Unidad de medida| Unidades de medida',
    unitPrice: 'Precio unitario',
    unknown: 'Desconocido|desconocido',
    unlimited: 'Ilimitado',
    update: 'Actualizar',
    updateDataCompanyForBill: 'Actualiza los datos de tu empresa para recibir tu factura',
    updatedBy: 'Actualizado por',
    uploadYourRut: 'Adjunta tu RUT',
    used: 'Usado',
    user: 'Usuario',
    username: 'E-mail',
    userName: 'Nombre usuario',
    valid: 'Valido',
    validateContract: 'Valida documentación para utilizar firma aliaddo',
    validateForm: 'Valida hay datos faltantes o mal diligenciados',
    validatePayment: 'Validar estado pago',
    validateYourCredential: 'Valide sus credenciales',
    value: 'Valor|Valores',
    valueChange: 'Cambio por valor',
    variant: 'Variante|Variantes',
    vendor: 'Vendedor|Vendedores',
    view: 'Ver',
    viewPayments: 'Ver pagos',
    void: 'Anular',
    voucher: 'Comprobante|Comprobantes|comprobante|comprobantes',
    voucherAlreadyAnnuled: 'Comprobante se encuentra en estado anulado',
    voucherAlreadyAnnuledMessage:
      'Este comprobante ya no se puede pagar porque se encuentra anulado, comunicate con facturación',
    voucherAlreadyPayed: 'El comprobante ya se encuentra en estado pago',
    voucherAlreadyPayedMessage:
      'En nuestro sistema ya reporta pago el comprobante, si tienes dudas por favor conmunicate con facturación',
    voucherAnnuled: 'Comprobante anulado',
    voucherIsFirst: 'Orden de apetura?',
    voucherPayed: 'Comprobante ya pago',
    wait: 'Espera',
    warehouse: 'Bodega|Bodegas',
    warning: 'Advertencia',
    waybillPurchase: 'Remisión de compra|Remisiones de compra',
    waybillSale: 'Remisión de venta|Remisiones de venta',
    website: 'Sitio Web',
    weight: 'Peso',
    weightPerUnit: 'Peso por unidad',
    welcome: 'Bienvenido',
    width: 'Ancho',
    withholding: 'Retención',
    year: 'Año|Años',
    yearly: 'Anual',
    yes: 'Si',
    youMustSelectYearOrCompany: 'Debes seleccionar año o empresa',
    yourIp: 'Tu Ip',
    identificationCc: 'Ingresa tu número de cédula',
    identificationNit: 'Ingresa el nit de tu empresa',
    identificationTe: 'Ingresa el número de tu cédula de extranjería',
    identificationPs: 'Ingresa el número de tu pasaporte',
    yourPlan: 'Tu plan',
    studentCardOrCertification: 'Carnet estudiantil o recibo matricula',
    institution: 'Institución educativa',
    cardExpiration: 'Fecha expiración matricula',
    yourPlanSeleced: 'Tu plan seleccionado',
    typeForSearch: 'Empieza a digitar para buscar',
    studentCard: 'Carnet Estudiantil',
    clickForSelectFile: 'Click para seleccionar archivo',
    dropHereStudentCard: 'Arrastra y deja aquí tu carnet o recibo de matricula'
  }
}
export const i18n = new VueI18n({
  locale: 'es',
  messages
})
