<template>
  <el-container class="full-height">
    <!-- <el-header class="pull-center mt-5" height="90">
      <el-row type="flex" justify="center">
        <el-col :xs="24" :sm="20" :md="14" :lg="10" :xl="8">
          <el-image
            v-if="signupCompany"
            fit="cover"
            :src="require(`@/assets/img/svg/register_banner_companies.svg`)"
            alt="Aliaddo"
            style="width: 100%"
          ></el-image>
          <el-image
            v-if="signupStudents"
            fit="cover"
            :src="require(`@/assets/img/svg/register_banner_students.svg`)"
            alt="Aliaddo"
            style="width: 100%"
          ></el-image>
        </el-col>
      </el-row>
    </el-header> -->
    <el-main class="center-screen">
      <el-row type="flex" justify="center">
        <el-col :xs="20" :sm="14" :md="12" :lg="10" :xl="8">
          <el-image
            v-if="signupCompany"
            fit="cover"
            :src="require(`@/assets/img/svg/register_banner_companies.svg`)"
            alt="Aliaddo"
            style="width: 100%"
          ></el-image>
          <el-image
            v-if="signupStudents"
            fit="cover"
            :src="require(`@/assets/img/svg/register_banner_students.svg`)"
            alt="Aliaddo"
            style="width: 100%"
          ></el-image>
        </el-col>
      </el-row>
      <el-row type="flex" justify="center">
        <el-col :span="24">
          <router-view />
        </el-col>
      </el-row>
    </el-main>
    <el-footer class="pt-5" style="background-color: #f1f1f1; font-size: 12px; height: 65px">
      <el-row type="flex" justify="center" align="middle">
        <el-col :xs="20" :sm="14" :md="12" :lg="10" :xl="8">
          <div class="text-center">
            {{ $tc('copyright') }}
          </div>
        </el-col>
      </el-row>
      <el-row type="flex" justify="center" align="middle">
        <el-col :xs="20" :sm="14" :md="12" :lg="10" :xl="8">
          <div class="text-center">
            <el-link href="https://aliaddo.com/blog" target="_blank" rel="noopener noreferrer">
              {{ $tc('blog') }}
            </el-link>
            &nbsp;-&nbsp;
            <el-link
              href="https://www.facebook.com/aliaddo.co"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i class="fab fa-facebook"></i>
            </el-link>
            &nbsp;-&nbsp;
            <el-link href="https://twitter.com/aliaddo_co" target="_blank" rel="noopener noreferrer">
              <i class="fab fa-twitter"></i>
            </el-link>
            &nbsp;-&nbsp;
            <el-link
              href="https://www.instagram.com/aliaddo.co"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i class="fab fa-instagram"></i>
            </el-link>
          </div>
        </el-col>
      </el-row>
    </el-footer>
  </el-container>
</template>
<script>
export default {
  name: 'SignupLayout',
  data() {
    return {
      loading: {
        service: false
      },
      model: {
        service: {
          serviceDomain: ''
        }
      }
    }
  },
  computed: {
    crmParams: _this => {
      if (_this.$route.query.product) {
        return _this.$route.query.product.split('-')
      }

      return []
    },
    signupCompany: _this => _this.$route.name === 'RegisterCompanies',
    signupStudents: _this => _this.$route.name === 'RegisterStudents',
    product: _this => {
      if (_this.$route.query.product && _this.$route.query.plan) {
        return _this.$route.query.product.toLowerCase()
      }
      if (_this.crmParams.length > 0) {
        return _this.crmParams[0].trim().toLowerCase()
      }

      return ''
    },
    service: _this => {
      if (_this.$route.query.product && _this.$route.query.plan) {
        return _this.$route.query.product.toLowerCase()
      }
      if (_this.crmParams.length > 0) {
        return _this.crmParams[0].trim().toLowerCase()
      }

      return ''
    },
    planName: _this => {
      if (_this.$route.query.plan) {
        return _this.$route.query.plan
      }
      if (_this.crmParams.length > 1) {
        return _this.crmParams[1].trim()
      }

      return ''
    },
    periodicidad: _this => {
      if (_this.$route.query.periodicidad) {
        return _this.$route.query.periodicidad
      }
      if (_this.crmParams.length > 2) {
        return _this.crmParams[2].trim()
      }

      return ''
    },
    serviceId: _this => {
      if (_this.product === 'fe') {
        return 1
      }
      if (_this.product === 'aliaddo') {
        return 2
      }
      if (_this.product === 'aliaddopos') {
        return 8
      }
      if (_this.product === 'isv') {
        return 7
      }
      if (_this.product === 'aliaddonominafull') {
        return 10
      }
      if (_this.product === 'aliaddonominalite') {
        return 11
      }
      if (_this.product === 'aliaddonominaisv') {
        return 12
      }

      return -1
    }
  },
  created() {
    this.getService()
  },
  methods: {
    onGoToService() {
      window.open(`https://${this.model.service.serviceDomain}`, '_blank')
    },
    getService() {
      const _this = this

      _this.loading.service = true

      _this.$http
        .get(`services/${_this.serviceId}`)
        .then(response => (_this.model.service = response.data))
        .finally(() => (_this.loading.service = false))
    }
  }
}
</script>
<style lang="scss">
main.el-main:first-child {
  padding: 0px;
}

.el-container .pattern-gradient-dark {
  background-image: url('../assets/img/background-cover.jpg'),
    linear-gradient(rgba(36, 150, 208, 0.9), rgb(208, 245, 255));
  background-size: 1440px, 100%;
  background-size: cover;
}

/* footer
-------------------------------------------------- */
.service-account {
  padding: 15px 20px;
  text-align: right;

  .sub {
    color: #aaa;
    text-align: right;
    margin: 20px 20px;
  }
}

/* End of footer
-------------------------------------------------- */
</style>
